import React from "react";
import '../styles/globals.css';
import { ThemeProvider } from 'next-themes';
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import Layout from '../components/layout';
import { Provider } from 'react-redux';
import { store,persistor } from '../redux/store';
import { useRouter } from 'next/router';
import Meta from '../components/Meta';
import UserContext from '../components/UserContext';
import { useRef } from 'react';
import { PersistGate } from "redux-persist/integration/react";
// import TagManager from "react-gtm-module"
import Script from "next/script";

// const tagManagerArgs = {
//   id: "GTM-KRJL7K3",
// }

function MyApp({ Component, pageProps }) {
	const [queryClient] = React.useState(() => new QueryClient());
	const router = useRouter();
	const pid = router.asPath;
	const scrollRef = useRef({
		scrollPos: 0,
	});
	// useEffect(() => {
	// 	TagManager.initialize(tagManagerArgs)
	//   }, [])
	return (
		<>
			<Meta title="Likizone | Liquidation, Wholesale Lots and Surplus marketplace" />
			<meta name="facebook-domain-verification" content="rwpg27fyoivyqdbw860tpuourh136y" />
			<Script id="gtm" strategy="afterInteractive">
        {`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-KRJL7K3');
        `}
      </Script>
      <noscript>
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-KRJL7K3"
          height="0"
          width="0"
          style={{display:'none',visibility:'hidden'}}
        ></iframe>
      </noscript>
			<Provider store={store}>
			<QueryClientProvider client={queryClient}>
				<ThemeProvider enableSystem={true} attribute="class">
				<PersistGate loading={null} persistor={persistor}>
       
						<UserContext.Provider value={{ scrollRef: scrollRef }}>
							{pid === '/login' ? (
								<Component {...pageProps} />
							) : (
								<Layout>
									<Component {...pageProps} />
								</Layout>
							)}
						</UserContext.Provider>
				</PersistGate>
				</ThemeProvider>
				<ReactQueryDevtools initialIsOpen={false} />
				
				</QueryClientProvider>
			</Provider>
		</>
	);
}

export default MyApp;
