import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// import { setMessage } from "./message";

// export const register = createAsyncThunk(
//   "auth/register",
//   async ({ username, email, password }, thunkAPI) => {
//     try {
//       const response = await AuthService.register(username, email, password);
//       // thunkAPI.dispatch(setMessage(response.data.message));
//       return response.data;
//     } catch (error) {
//       const message =
//         (error.response &&
//           error.response.data &&
//           error.response.data.message) ||
//         error.message ||
//         error.toString();
//       // thunkAPI.dispatch(setMessage(message));
//       return thunkAPI.rejectWithValue();
//     }
//   }
// );

// export const login = createAsyncThunk(
//   "auth/login",
//   async ({ username, password }, thunkAPI) => {
//     try {
//       const data = await AuthService.login(username, password);
//       return { user: data };
//     } catch (error) {
//       const message =
//         (error.response &&
//           error.response.data &&
//           error.response.data.message) ||
//         error.message ||
//         error.toString();
//       // thunkAPI.dispatch(setMessage(message));
//       return thunkAPI.rejectWithValue();
//     }
//   }
// );

// export const logout = createAsyncThunk("auth/logout", async () => {
//   await AuthService.logout();
// });
const initialState = {
  isLoggedIn: false,
  user: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state, action) => {
      const data=action.payload;
      state.user=data;
      state.isLoggedIn=true;
      // return {user:data,isLoggedIn:true}
    },
    updateProfile:(state,action)=>{
      console.log(action,'action')
      state.user={...state.user,...action.payload}
    },
    updateSubscription:(state,action)=>{
      console.log("reset")
      state.user.subscriptionId=action.payload
    },
    logout: (state, action) => {
      state.user=null;
      state.isLoggedIn=false;
      // return {user:data,isLoggedIn:true}
    },
  },
});

const { reducer } = authSlice;
export const {login,logout,updateProfile,updateSubscription} = authSlice.actions;

export default reducer;
