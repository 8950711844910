import { combineReducers, configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
// import counterReducer from '../features/counter/counterSlice';
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
// import storageSession from '@reduxjs/toolkit/';
import counterReducer from "./counterSlice";
import authReducer from "./auth";
const persistConfig = {
  key: "root",
  storage,
  stateReconciler: autoMergeLevel2,
};
const userPersistConfig = {
  key: "user",
  storage: storage,
};
const rootReducer = combineReducers({
  user: persistReducer(userPersistConfig, authReducer),
  counter: counterReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
//   middleware: [thunk],
});
export const persistor = persistStore(store);
