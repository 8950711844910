import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { axiosIns } from "../../config/axiosIns";
import { bidsModalHide } from "../../redux/counterSlice";
import { useMutation } from "react-query";
import Link from "next/link";

const postRequest = (data) =>
  axiosIns.post(`/truckloads/requests/${data.truckload}`, data);

const BidsModal = () => {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();
  const { bidsModal, selectedTruckload } = useSelector(
    (state) => state.counter
  );
  const dispatch = useDispatch();

  const { mutate, isLoading } = useMutation((values) =>
    postRequest({ ...values, truckload: selectedTruckload })
  );

  const onSubmit = (data) => {
    mutate(data, {
      onSuccess: () => {
        reset({
          name: "",
          phone: "",
          email: "",
          terms: false,
          notes: "",
        });
        dispatch(bidsModalHide());
      },
    });
  };

  return (
    <div>
      <div className={bidsModal ? "modal fade show block" : "modal fade"}>
        <div className="modal-dialog max-w-2xl max-h-screen">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="placeBidLabel">
                Buy Truckload
              </h5>
              <button
                type="button"
                className="btn-close"
                onClick={() => dispatch(bidsModalHide())}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  className="fill-jacarta-700 h-6 w-6 dark:fill-white"
                >
                  <path fill="none" d="M0 0h24v24H0z"></path>
                  <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"></path>
                </svg>
              </button>
            </div>

            {/* <!-- Body --> */}
            <div className="modal-body p-6">
              <div className="mb-2">
                <label
                  htmlFor="item-name"
                  className="font-display text-jacarta-700 mb-2 block dark:text-white"
                >
                  Full Name<span className="text-red">*</span>
                </label>
                <input
                  type="text"
                  id="item-name"
                  {...register("name", {
                    required: { value: true, message: "Fullname required" },
                    min: {
                      value: 2,
                      message: "Name should ne be less than 2 charachters",
                    },
                    max: {
                      value: 5,
                      message: "name should not be longer than 30 charachter",
                    },
                  })}
                  className="dark:bg-jacarta-700 border-jacarta-100 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:placeholder:text-jacarta-300 w-full rounded-lg py-3 px-3 hover:ring-2 dark:text-white"
                  placeholder="John Doe ..."
                />
                {errors.name && (
                  <p className="text-red">{errors.name?.message}</p>
                )}
              </div>
              <div className="mb-2">
                <label
                  htmlFor="item-name"
                  className="font-display text-jacarta-700 mb-2 block dark:text-white"
                >
                  Email<span className="text-red">*</span>
                </label>
                <input
                  type="text"
                  id="item-name"
                  {...register("email", {
                    required: { value: true, message: "Email required" },
                    pattern: {
                      value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g,
                      message: "Please provide a valid email", // JS only: <p>error message</p> TS only support string
                    },
                  })}
                  className="dark:bg-jacarta-700 border-jacarta-100 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:placeholder:text-jacarta-300 w-full rounded-lg py-3 px-3 hover:ring-2 dark:text-white"
                  placeholder="johndoe@example.com"
                />
                {errors.email && (
                  <p className="text-red">{errors.email?.message}</p>
                )}
              </div>
              <div className="mb-2">
                <label
                  htmlFor="item-name"
                  className="font-display text-jacarta-700 mb-2 block dark:text-white"
                >
                  Phone
                </label>
                <input
                  type="text"
                  id="item-name"
                  {...register("phone", {
                    pattern: {
                      value:
                        /^(1\s?)?(\d{3}|\(\d{3}\))[\s\-]?\d{3}[\s\-]?\d{4}$/gm,
                      message: "Please provide a valid phone number", // JS only: <p>error message</p> TS only support string
                    },
                  })}
                  className="dark:bg-jacarta-700 border-jacarta-100 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:placeholder:text-jacarta-300 w-full rounded-lg py-3 px-3 hover:ring-2 dark:text-white"
                  placeholder="(555) 555-5555"
                />
                {errors.phone && (
                  <p className="text-red">{errors.phone?.message}</p>
                )}
              </div>
              <div className="mb-2">
                <label
                  htmlFor="item-name"
                  className="font-display text-jacarta-700 mb-2 block dark:text-white"
                >
                  Other Details
                </label>
                <textarea
                  type="text"
                  id="item-name"
                  {...register("notes")}
                  rows={4}
                  className="dark:bg-jacarta-700 border-jacarta-100 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:placeholder:text-jacarta-300 w-full rounded-lg py-3 px-3 hover:ring-2 dark:text-white"
                  placeholder="Add more details..."
                />
              </div>

              {/* <!-- Terms --> */}
              <div className="mt-4 flex items-center space-x-2">
                <input
                  type="checkbox"
                  id="terms"
                  {...register("terms", {
                    required: {
                      value: true,
                      message:
                        "You need to accept terms in order to send request",
                    },
                  })}
                  className="checked:bg-accent dark:bg-jacarta-600 text-accent border-jacarta-200 focus:ring-accent/20 dark:border-jacarta-500 h-5 w-5 self-start rounded focus:ring-offset-0"
                />

                <label
                  htmlFor="terms"
                  className="dark:text-jacarta-200 text-sm"
                >
                  By checking this box, I agree to {"Likizone's"}{" "}
                  <Link href="/terms" className="text-accent">
                    Terms of Service
                  </Link>
                </label>
              </div>
              {errors.terms && (
                <p className="text-red">{errors.terms?.message}</p>
              )}
            </div>
            {/* <!-- end body --> */}

            <div className="modal-footer">
              <div className="flex items-center justify-center space-x-4">
                <button
                  onClick={handleSubmit(onSubmit)}
                  disabled={isLoading}
                  type="button"
                  className="bg-accent shadow-accent-volume hover:bg-accent-dark rounded-full py-3 px-8 text-center font-semibold text-white transition-all"
                >
                  Request
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BidsModal;
