import axios from 'axios';

const baseURL = `${process.env.NEXT_PUBLIC_API_BASE_URL}/api/v1`;

export const axiosIns = axios.create({ baseURL });
axiosIns.defaults.headers.common['Content-Type'] = 'application/json';
axiosIns.defaults.headers.common['Accept'] = 'application/json';
axiosIns.defaults.crossDomain = true;

export const axiosIns2 = axios.create({ baseURL });
axiosIns2.defaults.headers.common['Content-Type'] = 'multipart/form-data';
axiosIns2.defaults.headers.common['Accept'] = 'application/json';
axiosIns2.defaults.crossDomain = true;
