const footerMenuList = [
  {
    id: 1,
    title: "Marketplace",
    diffClass: "md:col-start-7",
    list: [
      {
        id: 1,
        href: "/",
        text: "Home",
      },
      {
        id: 2,
        href: "/explore_truckloads",
        text: "Truckloads",
      },
      {
        id: 3,
        href: "/merchant/explore_sellers",
        text: "Sellers",
      },
      {
        id: 4,
        href: "/ecommerce",
        text: "Sell Online With Ecommerce",
      },  
    ],
  },
  {
    id: 2,
    title: "Company",
    diffClass: "",
    list: [
      {
        id: 1,
        href: "/about",
        text: "About",
      },
      {
        id: 2,
        href: "/contact",
        text: "Contact Us",
      },
      // {
      //   id: 3,
      //   href: "/blog",
      //   text: "Our Blog",
      // },
      {
        id: 3,
        href: "/faq",
        text: "FAQ",
      },
      {
        id: 4,
        href: "/terms",
        text: "Terms of Service",
      },
    ],
  },
  {
    id: 3,
    title: "My Account",
    diffClass: "",
    list: [
      {
        id: 1,
        href: "/login",
        text: "Login",
      },
      {
        id: 2,
        href: "/create",
        text: "Register",
      },
      {
        id: 3,
        href: "/reset",
        text: "Forgot password ?",
      },
    ],
  },
];

const socialIcons = [
  {
    id: 1,
    href: "https://www.facebook.com/likizone",
    text: "facebook",
  },
  {
    id: 2,
    href: "https://www.twitter.com/likizone",
    text: "twitter",
  },
  {
    id: 3,
    href: "https://www.instagram.com/likizone",
    text: "instagram",
  }
];

export { footerMenuList, socialIcons };
