import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useRouter } from 'next/router'
function useUser(redirect) {
  const user = useSelector((state) => state.user);
  const router=useRouter();
  useEffect(() => {
    // console.log(user);
    // if(user.isLoggedIn && user.user.status==='inactive'){
    //   router.push('/login')
    // }
    // if(user.isLoggedIn && user.user.status==='inreview'){
    //   router.push('/login')
    // }
    if(!user.isLoggedIn&&redirect){
        router.push('/login')
    }
  }, [user,router,redirect]);

  return [user.user,user.isLoggedIn];
}
export default useUser;