import React from "react";
import Head from "next/head";

const Meta = ({ title, keyword, desc }) => {
  return (
    <div>
      <Head>
        <title>{title}</title>
        <link rel="icon" href="/favicon.png" />
        <meta name="description" content={desc} />
        <meta name="keyword" content={keyword} />
        <meta name="facebook-domain-verification" content={process.env.NEXT_PUBLIC_FACEBOOK_VERIFICATION} />
      </Head>
    </div>
  );
};

Meta.defaultProps = {
  title: "Likizone | Liquidation, Wholesale Lots and Surplus marketplace",
  keyword:
    "liquidation, sell returns, pallets, sell liquidation direct, truckloads pallets",
  desc: "Shop Thousands Of Liquidation Auctions. Hundreds Of Product Categories. All Lot Sizes And Auctions",
};

export default Meta;
